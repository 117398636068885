@tailwind base;
@tailwind components;
@tailwind utilities;

.leaflet-container{
    height: 350px;
    width: 600px;
}

@media only screen and (max-width: 600px) {
    .leaflet-container{
        width: 350px;
    }
  }

  @media only screen and (max-width: 1000px) {
    .leaflet-container{
        margin-top: 10px
    }
  }

#root{
    background:linear-gradient(to right, #ffcdcd, #fffdce);
}

#forecastChart canvas{
    height: 450px;
}